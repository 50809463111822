import styled from '@emotion/styled';

const Iframe = styled.iframe`
    width: 100%;
    max-width: 1024px;
    height: 422px;
    border: 0;

    @media (min-width: 1024px) {
        max-width: 933px;
        border-radius: 59px;
    }
`;

export const Map = () => {
    return (
        <Iframe
            title="simhairy dental location on map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6711.636312141554!2d-116.93808828417477!3d32.74401894697391!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d95bd7ac4234c5%3A0xa747680a1d4b6c18!2sSimhairy%20Dental!5e0!3m2!1sen!2s!4v1647582719680!5m2!1sen!2s"
            allowFullScreen
            loading="lazy"
            id="map"
        ></Iframe>
    );
};
