import { ContactForm } from '@/ContactForm';
import { ContactInfo } from '@/ContactInfo';
import { Hours } from '@/Hours';
import { Seo } from '@/layout/Seo';
import { flexCenter, flexSpace, px } from '@/layout/styles/classes';
import { Map } from '@/Map';
import styled from '@emotion/styled';
import { Fragment } from 'react';

const Contact = styled.section`
    ${px};
    padding-top: 362px;
    padding-bottom: 64px;
    margin-top: -300px;
    margin-bottom: 64px;
    background-image: ${({ theme }) => theme.colors.gradient};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0px 0px 64px 64px;

    > iframe {
        border-radius: 59px;
        margin: 64px auto 0;
        display: block;
    }

    @media (min-width: 1024px) {
        padding-top: 396px;
        margin-bottom: 120px;
    }
`;

const Flex = styled.div`
    > div {
        margin-bottom: 64px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        > div {
            ${flexSpace};
            align-items: flex-start;

            & > div {
                margin-top: 0;
                margin-bottom: 32px;
            }
        }
    }
    @media (min-width: 1024px) {
        ${flexCenter};
        align-items: flex-start;

        > div {
            margin-bottom: 0;
        }
    }
`;

export default function ContactUs() {
    return (
        <Fragment>
            <Seo
                title="Contact Us | Simhairy Dental"
                description="Contact the team at Simhairy Dental and schedule your first visit today."
            />
            <Contact>
                <Flex>
                    <div>
                        <ContactInfo contactPage />
                        <Hours white="true" />
                    </div>
                    <ContactForm />
                </Flex>
                <Map />
            </Contact>
        </Fragment>
    );
}
